import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { PromotionBanner } from '../../promotionBanner/PromotionBanner'
import { textWithLocale } from '../../../../common/i18n'
import { Product, Service } from '../../../context/reducers/cestaReducer'
import Size from '../../../utils/media-queries-setup'
import DropDown from '../../dropDown/DropDown'
import Recomendado from '../../Recomendado/Recomendado'
import styles from './index.module.scss'
import Price from './Price'
import RowWithCheckbox from './RowWithCheckbox'
import RowWithoutCheckbox from './RowWithoutCheckbox'

const initialOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
]

const initialOptionsMoto = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
]

interface CestaItemsProps {
  item: Partial<Product> | Service
  hasDelete?: boolean
  handleQuantityChange?: (value: number, id_navision: string) => void
  onDeleteItem?: (id_navision: string) => void
  withCheckBox?: boolean
  handleCheckBoxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  titleClass?: string
  highlighted?: boolean
  discountServicio?: number
}

const Check = () => (
  <div className={styles.icon_check_free_wrapper}>
    <FontAwesomeIcon icon={faCheck} />
  </div>
)

export default function CestaItem({
  item,
  hasDelete,
  handleQuantityChange,
  onDeleteItem,
  withCheckBox,
  handleCheckBoxChange,
  titleClass,
  highlighted,
  discountServicio,
}: CestaItemsProps) {
  const nombreProducto: string | null = item[textWithLocale('nombre_producto')]
    ? item[textWithLocale('nombre_producto')].trim()
    : null
  const descripcionProducto: string | null =
    item[textWithLocale('descripcion')] || null
  const masInfoTitulo: string | null =
    item[textWithLocale('mas_info_titulo')] || null
  const masInfo: string | null = item[textWithLocale('mas_info')] || null
  const isFree = item.importe === 0

  const onChangeQuantity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleQuantityChange(parseInt(event.target.value), item.id_navision)
  }

  const options =
    item.categoria === 'coche' ? initialOptions : initialOptionsMoto
  return (
    <div
      className={`${styles.selected_item_container} ${
        highlighted ? styles.discount_backgrounded : ''
      }`}>
      {isFree ? (
        <div className={styles.check_and_row_wrapper}>
          <Check />
          <RowWithoutCheckbox
            item={item}
            onChangeQuantity={onChangeQuantity}
            nombreProducto={nombreProducto}
            masInfoTitulo={masInfoTitulo}
            masInfo={masInfo}
            descripcionProducto={descripcionProducto}
            titleClass={titleClass}
            initialOptions={options}
          />
        </div>
      ) : withCheckBox ? (
        <RowWithCheckbox
          item={item}
          handleCheckBoxChange={handleCheckBoxChange}
          nombreProducto={nombreProducto}
          masInfoTitulo={masInfoTitulo}
          masInfo={masInfo}
          descripcionProducto={descripcionProducto}
        />
      ) : (
        <RowWithoutCheckbox
          item={item}
          onChangeQuantity={onChangeQuantity}
          nombreProducto={nombreProducto}
          masInfoTitulo={masInfoTitulo}
          masInfo={masInfo}
          descripcionProducto={descripcionProducto}
          titleClass={titleClass}
          initialOptions={options}
        />
      )}

      <Size.Matcher
        mobile={null}
        desktop={
          'recomendado' in item && item.recomendado === 1 ? (
            <Recomendado />
          ) : null
        }
      />
      <Size.Matcher
        mobile={null}
        desktop={
          item.banner && item.banner.visible_cesta ? (
            <PromotionBanner promocion={item.banner} />
          ) : null
        }
      />
      <div
        className={
          hasDelete
            ? styles.selected_item_right_section_with_delete
            : styles.selected_item_right_section_without_delete
        }>
        <div className={styles.dropdown_container}>
          {item.cantidad &&
          ['neumatico', 'camara', 'mousse', 'bateria', 'aceite'].includes(
            item.tipo_producto
          ) ? (
            <DropDown
              size="small"
              onChange={onChangeQuantity}
              defaultValue={item.cantidad.toString()}
              options={options}
              labelAbove={true}
              labelId={` `}
            />
          ) : null}
        </div>
        <div className={styles.price_container}>
          <Price
            item={item}
            discountServicio={discountServicio}
            withCheckBox={withCheckBox}
          />
          {hasDelete && (
            <div
              onClick={() => onDeleteItem(item.id_navision)}
              className={styles.delete_icon_container}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
